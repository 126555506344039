import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ProfileHeader from '../components/ProfileHeader';
import { SessionContext } from '../helper/SessionContext';
import { formatDateString } from '../helper/helper';
import Footer from '../components/Footer';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD');
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT;

const History = () => {
    const [userdata, setUserdata] = useState(null)
    const [searchHistory, setSearchHistory] = useState([])
    const { sessionId, clearSessionId } = useContext(SessionContext);
    const [hasFetched, setHasFetched] = useState(false);
    const navigate = useNavigate();


    const fetchUser = useCallback(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/me`,
            headers: {
                'Authorization': sessionId
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setUserdata(data.user_data);
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 401) {
                    clearSessionId();
                    navigate(`/auth`);
                }
            });
    }, [sessionId, navigate, clearSessionId]);

    const fetchHistory = useCallback(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/history`,
            headers: {
                'Authorization': sessionId
            }
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setSearchHistory(data.search_history)
                    console.log(data.search_history[0].created_at)
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 401) {
                    clearSessionId();
                    navigate(`/auth`);
                }
            });
    }, [sessionId, navigate, clearSessionId]);

    useEffect(() => {
        if (sessionId && !hasFetched) {
            fetchUser();
            fetchHistory();
            setHasFetched(true);
        }
    }, [sessionId, hasFetched, fetchUser, fetchHistory]);
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Vaaycay - History</title>
                <meta name="description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />
                <meta name="keywords" content="AI travel assistant, travel deals, cheap flights, hotel discounts, visa information, vacation planning, best travel prices" />
                <meta name="author" content="Vaaycay" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />


                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vaaycay.com" />
                <meta property="og:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="og:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://vaaycay.com" />
                <meta property="twitter:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="twitter:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />

            </Helmet>

            <ProfileHeader content={userdata} />

            <body class="bg-white min-h-screen">
                <div class="container mx-auto px-4 py-8">
                    <h1 class="text-3xl font-bold mb-6 text-center text-gray-800">Search History</h1>

                    <div class="max-w-2xl mx-auto bg-white rounded-3xl border-2 border-gray-300 p-6">
                        <ul class="space-y-4">
                        {searchHistory.length > 0 ? (
                        searchHistory.map((item, index) => (
                            <li>
                                <Link to={`/details?trip_id=${item.trip_id}`} rel='noreferrer' target='_blank' class="block hover:bg-gray-50 rounded-lg p-4 transition duration-300 ease-in-out">
                                    <div class="flex items-center justify-between">
                                        <span class="text-lg text-black hover:underline">{item.title}</span>
                                        <span class="text-sm text-gray-500">{formatDateString(item.created_at)}</span>
                                    </div>
                                </Link>
                            </li>
                        ))) : (
                            <div>

                            </div>
                        )}
                        </ul>
                    </div>
                </div>
            </body>
            <Footer />
        </div>
    )
}

export default History