import React from 'react';
import { Link } from 'react-router-dom';
const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD')
const one_time_url = is_prod ? process.env.REACT_APP_CHECKOUT_PROD : process.env.REACT_APP_CHECKOUT_STAGING
const subscription_url = is_prod ? process.env.REACT_APP_SUBSCRIPTION_PROD : process.env.REACT_APP_SUBSCRIPTION_STAGING

const Pricing = ({ showHeaderAndDescription = true }) => {
  return (
    <div className="bg-white py-4 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {showHeaderAndDescription && (
          <>
            <div className="mx-auto max-w-4xl text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Clear Pricing</p>
            </div>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">Try it out for less than a cup of coffee ☕️. Subscribe for more features and higher search power 🔥</p>
          </>
        )}

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          <section className="p-8">
            <h3 id="tier-basic" className="text-sm font-semibold leading-6 text-gray-900">Basic</h3>
            <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
              <span className="text-4xl font-bold">$5</span>
              <span className="text-sm font-semibold">/ 10 Search credits</span>
            </p>
            <Link to={one_time_url}
              aria-describedby="tier-basic"
              className="mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              target={"_blank"}
              rel="noopener noreferrer"
            >Buy credits</Link>
            <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Private search </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Shared links </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Search history </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Email support </span>
              </li>
            </ul>
          </section>
          <section className="rounded-xl bg-gray-400/5 p-8 ring-1 ring-inset ring-gray-200">
            <h3 id="tier-essential" className="text-sm font-semibold leading-6 text-gray-900">Essential</h3>
            <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
              <span className="text-4xl font-bold">$24.99</span>
              <span className="text-sm font-semibold">/month</span>
            </p>
            <Link to={subscription_url}
              aria-describedby="tier-essential"
              className="mt-8 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              target={"_blank"}
              rel="noopener noreferrer"
            >Subscribe</Link>
            <ul className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Private search </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Shared links </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Search history </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Price monitoring </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span>
                  Monthly search credits
                  <span className="text-sm leading-6 text-gray-500">(60 Monthly)</span>
                </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span> Email support </span>
              </li>
              <li className="flex gap-x-3">
                <svg className="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                </svg>
                <span>Priority message support</span>
              </li>
            </ul>
          </section>
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
              <div className="flex w-1/4 px-4" style={{ marginLeft: "50%" }} aria-hidden="true">
                <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5"></div>
              </div>
            </div>
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">
                Pricing plan comparison
              </caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td></td>
                  <th scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                    <div className="text-sm font-semibold leading-7 text-gray-900">Starter</div>
                  </th>
                  <th scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                    <div className="text-sm font-semibold leading-7 text-gray-900">Traveller</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row"><span className="sr-only">Price</span></th>
                  <td className="px-6 pt-2 xl:px-8">
                    <div className="flex items-baseline gap-x-1 text-gray-900">
                      <span className="text-4xl font-bold">$5</span>
                      <span className="text-sm font-semibold leading-6">/ 10 Search credits</span>
                    </div>
                    <Link
                      to={one_time_url}
                      className="mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      Buy credits
                    </Link>

                  </td>
                  <td className="px-6 pt-2 xl:px-8">
                    <div className="flex items-baseline gap-x-1 text-gray-900">
                      <span className="text-4xl font-bold">$24.99</span>
                      <span className="text-sm font-semibold leading-6">/month</span>
                    </div>
                    <Link to={subscription_url}
                      aria-describedby="tier-essential"
                      className="mt-8 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >Subscribe</Link>
                  </td>
                </tr>
                <tr>
                  <th scope="colgroup" colSpan="3" className="pb-4 pt-8 text-sm font-semibold leading-6 text-gray-900">
                    Features
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10"></div>
                  </th>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Private search
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Shared links
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Search history
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Price monitoring
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Not included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Monthly search credits
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Not included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <div className="text-center text-sm leading-6 text-gray-500">60 Monthly</div>
                  </td>
                </tr>

                <tr>
                  <th scope="colgroup" colSpan="3" className="pb-4 pt-16 text-sm font-semibold leading-6 text-gray-900">
                    Support
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10"></div>
                  </th>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Email support
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                    Priority message support
                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"></div>
                  </th>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Not included</span>
                  </td>
                  <td className="px-6 py-4 xl:px-8">
                    <svg className="mx-auto h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Included</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
