import React, { useState, useRef } from 'react';

const PlayButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const iframeRef = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
        if (iframeRef.current) {
            iframeRef.current.src += "?autoplay=1";
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src.replace("?autoplay=1", "");
        }
    };

    return (
        <div className="flex items-center justify-center">
            {/* Play Button */}
            <div 
                onClick={openModal} 
                className="play-button cursor-pointer flex items-center justify-center bg-indigo-600 w-18 h-18 rounded-full hover:bg-indigo-700 transition-transform transform hover:scale-110">
                <i className="fas fa-play text-white text-3xl"></i>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative bg-white p-4 rounded-lg w-11/12 max-w-2xl">
                        <button 
                            onClick={closeModal} 
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl">
                            &times;
                        </button>
                        <div className="w-full">
                            <iframe 
                                ref={iframeRef} 
                                className="w-full h-96"
                                src="https://www.youtube.com/embed/WyZokaxTvcI?si=qhWAQTPwfYtNuJKc"
                                title="YouTube video player" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlayButton;
