import React, { useState, useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Loader2 } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import PlayButton from '../components/PlayButton';
import RecentSearches from '../components/RecentSearches';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../helper/SessionContext';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD')
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT


const Home = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [recentSearches, setRecentSearches] = useState([])
    const requestComplete = useRef(false);
    const [query, setQuery] = useState('');
    const [ip, setIP] = useState("");
    const { sessionId, clearSessionId } = useContext(SessionContext);
    const navigate = useNavigate();
    const [hasFetched, setHasFetched] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        requestComplete.current = false;

        setTimeout(() => {
            if (!requestComplete.current) {
                setIsLoading(false);
                toast("😢 The request has timed out. You will not be charged.")
            }
        }, 20000);

        if (query !== '') {
            let data = JSON.stringify({
                "query": query,
                "ip_address": ip
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${base_url}/search`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionId
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setIsLoading(false)
                    requestComplete.current = true;
                    const data = response.data
                    if (response.status === 200) {
                        navigate(`/details?trip_id=${data.request_id}`);
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    requestComplete.current = true;
                    if (error.response?.status === 401) {
                        clearSessionId()
                        navigate(`/auth`);
                    } else {
                        toast(`😢 ${error.response?.data?.message || 'Your request was unsuccessful, Please try again.'}`)
                    }
                });
        }
    }


    const getIp = async () => {
        try {
            const res = await axios.get("https://corsproxy.io/?https://api.ipify.org/?format=json");
            setIP(res.data?.ip || '');
        } catch (error) {
            console.log(error)
        }
    };

    const getHomeData = async () => {
        try {
            const res = await axios.get(`${base_url}/home`);
            if (res.status === 200) {
                setRecentSearches(res.data.recent_searches)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!hasFetched) {
            getHomeData()
            getIp()
            setHasFetched(true);
        }
    }, [hasFetched])
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Vaaycay - Your AI Travel Assistant</title>
                <meta name="description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />
                <meta name="keywords" content="AI travel assistant, travel deals, cheap flights, hotel discounts, visa information, vacation planning, best travel prices" />
                <meta name="author" content="Vaaycay" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />


                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vaaycay.com" />
                <meta property="og:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="og:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://vaaycay.com" />
                <meta property="twitter:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="twitter:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />

            </Helmet>

            <Header />
            <main className="container mx-auto mt-8 p-4">
                <div className="text-center">
                    <h1 className="text-4xl font-bold">Your perfect vacation starts here.</h1>
                    <h3 className="text-lg text-gray-600 m-4 mb-8">
                    Let us find your flight, accommodation, and visa requirements while you focus on having a great time.
                    </h3>
                    <PlayButton />
                    <div className="mt-4 flex justify-center space-x-4"></div>
                    <form className="mt-4 flex justify-center" onSubmit={handleSubmit}>
                        <div className="flex items-center bg-gray-100 rounded-full p-2 mb-4 mt-4 md:w-2/3">
                            <i className="fas fa-search w-4 h-4 mr-2 px-4"></i>
                            <input
                                type="text"
                                placeholder="A trip to Cancun for 2 people..."
                                className="bg-transparent flex-grow outline-none"
                                value={query}
                                required
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded-full ml-2"
                                disabled={isLoading}
                            >
                                {isLoading ?
                                    <Loader2 className="w-6 h-6 animate-spin" />
                                    :
                                    'Search'
                                }
                            </button>
                        </div>
                    </form>
                    <p className="text-gray-500 text-sm px-10">
                        Include your city, destination, number of people, dates, info about your passport, and other details to find the perfect trip.
                    </p>
                </div>
                <div
                    className="mx-auto mt-16 md:m-16 bg-blue-50 p-8 rounded-lg flex items-center justify-center text-center relative h-[350px]"
                    style={{
                        backgroundImage: "url('https://res.cloudinary.com/verifiedlyllc/image/upload/v1718496415/m7mlyyckmuwc50fntu1q.svg')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                >
                    <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div>
                    <div className="relative z-10">
                        <h2 className="text-2xl font-bold text-white">Vacation within your budget.</h2>
                        <p className="mt-2 text-white">
                            We search the entire internet for the best prices without sacrificing quality.
                        </p>
                        <Link
                            to="/profile"
                            className="inline-block mt-4 bg-white text-black font-semibold py-3 px-4 rounded hover:opacity-75 transition-opacity duration-300"
                        >
                            Try it out
                        </Link>
                    </div>
                </div>
            </main>

            <RecentSearches content={recentSearches} />
            <Pricing />
            <Footer />
            <ToastContainer />
        </div>
    )
}

export default Home