// utils/flightUtils.js

/**
 * Function to get flight summary, stops info, and carrier logo from flight data
 * @param {Object} flightData - The flight data
 * @returns {Object} - An object containing flightSummary, stopsInfo, and carrierLogo
 */
export const getFlightSummary = (flightData) => {
    const trips = flightData.trip_breakdown;
    if (!trips || trips.length === 0) return null;
  
    const outboundFlight = trips[0];
    const inboundFlight = trips[1];
  
    if (
      !outboundFlight ||
      !outboundFlight.departure_airport ||
      !outboundFlight.depature_country ||
      !outboundFlight.arrival_airport ||
      !outboundFlight.arrival_country ||
      !inboundFlight ||
      !inboundFlight.departure_airport ||
      !inboundFlight.depature_country ||
      !inboundFlight.arrival_airport ||
      !inboundFlight.arrival_country
    ) {
      return null;
    }
  
    //const departureAirport = outboundFlight.departure_airport;
    const departureCountry = outboundFlight.depature_country;
    //const arrivalAirport = outboundFlight.arrival_airport;
    const arrivalCountry = outboundFlight.arrival_country;
    const outboundCarrierLogo = outboundFlight.legs[0].carriers[0].carrier_logo;
    const outboundCarrier = outboundFlight.legs[0].carriers[0].carrier_name
    const inboundCarrier = inboundFlight.legs[0].carriers[0].carrier_name
    const inboundCarrierLogo = inboundFlight.legs[0].carriers[0].carrier_logo;
  
    //const flightSummary = `${departureCountry} (${departureAirport}) to ${arrivalCountry} (${arrivalAirport}) and back`;
    const outboundStops = outboundFlight.legs.length - 1;
    const inboundStops = inboundFlight.legs.length - 1;
    const totalStops = outboundStops + inboundStops;
  
    const stopsInfo =
      totalStops === 0
        ? 'Non-stop'
        : `${totalStops} stop${totalStops > 1 ? 's' : ''}`;

        const flightSummary = `${departureCountry} to ${arrivalCountry} and back. ${stopsInfo}`;
  
    return {
      flightSummary,
      stopsInfo,
      outboundCarrierLogo,
      inboundCarrierLogo,
      outboundCarrier,
      inboundCarrier
    };
  };