import React, { useState } from 'react';
import { formatCurrency } from '../helper/FormatCurrency';
import Modal from '../components/Modal';
import ImageGallery from '../components/ImageGallery';

const AirBnb = ({ content, description }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const openModal = (item) => {
        setSelectedItem(item);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setModalIsOpen(false);
    };

    return (
        <section className="mt-6">
            <h2 className="text-2xl font-bold">Airbnb</h2>
            <p className="mt-2 text-gray-600">{description}</p>
            <div className="mt-4 overflow-x-auto snap-x" id="scrollContainer">
                <div className="flex space-x-4">
                    {content.length > 0 ? (
                        content.map((item, index) => (
                            <div key={index} className="bg-white flex-shrink-0 w-72 overflow-hidden  snap-center">
                                <img
                                    src={item.secondary_images[0]}
                                    alt={item.name}
                                    className="w-full h-48 object-cover rounded-lg"
                                />
                                <div className="py-4">
                                    <button
                                        onClick={() => openModal(item)}
                                        className="w-full text-left"
                                    >
                                        <h3 className="text-lg font-semibold">{item.name}</h3>
                                    </button>
                                    <div className="flex items-center mt-2">
                                        <span className="bg-green-500 text-white text-xs font-bold rounded-full px-2 py-1 mr-2">
                                            {formatCurrency(item.pricing.total, 'en-US', item.pricing.currency)}
                                        </span>
                                        <span className="text-gray-600">
                                            {item.ratings.star_rating} ({item.ratings.rating_count} reviews)
                                        </span>
                                    </div>
                                    <p className="text-gray-600 mt-1 text-sm">Price listed is for the duration of your stay.</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        Array(4).fill().map((_, index) => (
                            <div key={index} className="bg-white rounded-lg flex-shrink-0 w-72 animate-pulse">
                                <div className="w-full h-48 bg-gray-300 rounded-lg mb-4"></div>
                                <div className="w-2/3 h-4 bg-gray-300 rounded mb-2"></div>
                                <div className="w-1/3 h-4 bg-gray-300 rounded mb-2"></div>
                                <div className="w-full h-4 bg-gray-300 rounded mb-2"></div>
                                <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Airbnb Details"
            >
                {selectedItem && (
                    <>
                    <ImageGallery images={selectedItem.secondary_images} />
                        <h2 className="text-lg font-semibold">{selectedItem.name}</h2>
                        <p>{formatCurrency(selectedItem.pricing.total, 'en-US', selectedItem.pricing.currency)}</p>
                        <p>{selectedItem.ratings.star_rating} ({selectedItem.ratings.rating_count} reviews)</p>
                        <a
                            href={selectedItem.booking_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-block px-4 py-3 mt-4 text-white bg-black rounded-lg"
                        >
                            Reserve on Airbnb
                        </a>
                    </>
                )}
            </Modal>
        </section>
    );
};

export default AirBnb;
