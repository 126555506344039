import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const handleImageClick = (index) => {
    setStartIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="container mx-auto mt-6">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2">
          <img
            src={images[0]}
            alt="Main"
            className="w-full h-full max-h-80 object-cover rounded-lg cursor-pointer"
            onClick={() => handleImageClick(0)}
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          {images.slice(1, 5).map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image}
                alt={`Small ${index}`}
                className="w-full h-40 object-cover rounded-lg cursor-pointer"
                onClick={() => handleImageClick(index + 1)}
              />
              {index === 3 && images.length > 5 && (
                <button
                  className="absolute inset-0 bg-black bg-opacity-50 text-white font-bold rounded-lg flex items-center justify-center"
                  onClick={() => handleImageClick(index + 1)}
                >
                  Show all photos
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
          <div className="relative w-full h-full">
            <button
              className="absolute top-4 right-4 text-white text-2xl z-50"
              onClick={() => setIsOpen(false)}
            >
              &times;
            </button>
            <Carousel
              selectedItem={startIndex}
              showThumbs={false}
              useKeyboardArrows={true}
              infiniteLoop
              autoPlay={false}
            >
              {images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Fullscreen ${index}`}
                    className="object-contain h-screen w-screen"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
