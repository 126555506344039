import React, { useEffect, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SessionContext } from '../helper/SessionContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD');
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT;

const Confirm = () => {
    const { storeSessionId } = useContext(SessionContext);
    const location = useLocation();
    const navigate = useNavigate();

    const confirmSession = useCallback(async () => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');

        if (token) {
            let data = { "token": token };

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${base_url}/auth/confirm`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            };

            try {
                const response = await axios.request(config);
                const data = response.data;

                if (response.status === 200) {
                    console.log('Auth success');
                    const sessionId = data.session.session_id;
                    storeSessionId(sessionId);
                    navigate(`/profile`);
                } 
            } catch (error) {
                console.log('Error:', error);
                if (error.response) {
                    toast.error(`${error.response.data?.message || 'Your request was unsuccessful, Please try again.'}`);
                } else {
                    toast.error('An unexpected error occurred. Please try again.');
                }
            }
        }
    }, [location.search, storeSessionId, navigate]);

    useEffect(() => {
        confirmSession();
    }, [confirmSession]);

    return (
        <div>
            Confirming session...
            <ToastContainer />
        </div>
    );
};

export default Confirm;
