import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header className="bg-white shadow">
            <div className="container mx-auto p-4 flex items-center justify-between">
                <div className="flex items-center">
                    <Link to="/">
                        <i className="fa-solid fa-plane-departure cursor-pointer"></i>
                    </Link>
                </div>
                <nav className="flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                       <Link to="/profile">
                       <i class="fa-solid fa-user"></i>
                        </Link> 
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
