import React from 'react'
import { safeExtractData } from '../helper/helper'

const Visa = ({ content }) => {
  return (
    <section class="flex flex-col md:flex-row justify-between mb-8">
      <div class="md:w-1/3">
        <h2 class="text-2xl font-bold mt-4">Visa Requirements</h2>
        <p class="mt-2 text-gray-600">{safeExtractData(content?.info)}</p>
        {content?.link ? (
          <a href={content?.link} class="inline-block px-4 py-3 mt-4 text-white bg-black rounded-lg">
            Apply for Visa
          </a>
        ) : ''}
      </div>

      <div className='md:w-[600px]'>
        <img src="https://res.cloudinary.com/verifiedlyllc/image/upload/v1718728856/p9bxpfgo9z7d73uqzqef.svg" alt='Travel illustration'></img>
      </div>
    </section>
  )
}

export default Visa