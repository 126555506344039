import React from 'react';
import { formatCurrency } from '../helper/FormatCurrency';

const FlightDetails = ({ tripData }) => {
    const formatTime = (time) => new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formatDate = (time) => new Date(time).toLocaleDateString();

    return (
        <div className="p-4 bg-white rounded-lg">
            {tripData.trip_breakdown.map((trip, tripIndex) => (
                <div key={tripIndex} className="mb-6">
                    <h2 className="text-xl font-bold mb-2">Flight to {trip.arrival_country}</h2>
                    <p className="text-gray-500 mb-4">{trip.legs.length - 1} {trip.legs.length - 1 === 1 ? 'stop' : 'stops'} · {Math.floor((new Date(trip.arrival_time) - new Date(trip.departure_time)) / 3600000)}h {(Math.floor((new Date(trip.arrival_time) - new Date(trip.departure_time)) / 60000)) % 60}m</p>

                    {trip.legs.map((leg, legIndex) => (
                        <div key={legIndex} className="flex items-start mb-4">
                            <div className="w-1/3">
                                <div className="flex flex-col">
                                    <h3 className="text-sm font-bold">{formatTime(leg.depature_time)} · {formatDate(leg.depature_time)}</h3>
                                    <p className="text-gray-600">{leg.depature_airport_code}</p>
                                    <p className="text-gray-600 text-sm">{leg.depature_airport}</p>
                                </div>
                            </div>
                            <div className="w-1/3 text-center">
                                <p className="text-gray-600">{Math.floor((new Date(leg.arrival_time) - new Date(leg.depature_time)) / 3600000)}h {(Math.floor((new Date(leg.arrival_time) - new Date(leg.depature_time)) / 60000)) % 60}m</p>
                                <div className="border-t border-gray-300 mt-1 mb-1">
                                    <div className="w-full text-center">
                                        {legIndex < trip.legs.length - 1 && (
                                            <p className="text-gray-600">Layover {Math.floor((new Date(trip.legs[legIndex + 1].depature_time) - new Date(leg.arrival_time)) / 3600000)}h {(Math.floor((new Date(trip.legs[legIndex + 1].depature_time) - new Date(leg.arrival_time)) / 60000)) % 60}m</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-1/3">
                                <div className="flex flex-col items-end">
                                    <h3 className="text-sm font-bold">{formatTime(leg.arrival_time)} · {formatDate(leg.arrival_time)}</h3>
                                    <p className="text-gray-600">{leg.arrival_airport_code}</p>
                                    <p className="text-gray-600 text-sm">{leg.arrival_airport}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-2">Fare Breakdown</h2>
                <div className="flex justify-between text-sm text-gray-700">
                    <p>Base Fare</p>
                    <p>{formatCurrency(tripData.pricing.breakdown.base, 'en-US', tripData.pricing.currency)}</p>
                </div>
                <div className="flex justify-between text-sm text-gray-700">
                    <p>Taxes and Fees</p>
                    <p>{formatCurrency(tripData.pricing.breakdown.tax, 'en-US', tripData.pricing.currency)}</p>
                </div>
                <div className="flex justify-between text-sm text-gray-700">
                    <p>Total</p>
                    <p>{formatCurrency(tripData.pricing.total, 'en-US', tripData.pricing.currency)}</p>
                </div>
            </div>
            <a
                href={tripData.booking_url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-4 py-3 mt-4 text-white bg-black rounded-lg"
            >
                Reserve Now
            </a>
        </div>
    );
};

export default FlightDetails;
