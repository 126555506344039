import React, { useState } from 'react';
import { formatCurrency } from '../helper/FormatCurrency';
import { getFlightSummary } from '../helper/flightUtils';
import FlightInfo from '../components/FlightInfo';
import Modal from '../components/Modal';
import FlightDetails from '../components/FlightDetails';

const Flights = ({ content, description }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalIsOpen(false);
  };
  return (
    <section className="flex flex-col md:flex-row justify-between">
      <div className="md:w-1/3">
        <h2 className="text-2xl font-bold mt-4">Flights</h2>
        <p className="mt-2 text-gray-600">{description}</p>
      </div>
      <div className="md:w-2/3 mt-8 md:mt-0 overflow-x-auto relative" id="scrollContainer">
        <div className="flex space-x-4 pb-16" id="scrollContent">
          {content.length > 0 ? (
            content.map((item, index) => {
              const flightSummaryInfo = getFlightSummary(item);
              return (
                <div key={index} className="bg-white flex-shrink-0 w-72 overflow-hidden">
                  <FlightInfo tripData={item} />
                  <div className="py-4">
                    <button
                      onClick={() => openModal(item)}
                      className="w-full text-left"
                    >
                      <h3 className="text-lg font-semibold">{flightSummaryInfo.outboundCarrier}</h3>
                    </button>
                    <div className="flex items-center mt-2">
                      <span className="bg-green-500 text-white text-xs font-bold rounded-full px-2 py-1 mr-2">{formatCurrency(item.pricing.total, 'en-US', item.pricing.currency)}</span>
                      <span className="text-black font-bold">{item.fare_name}</span>
                    </div>
                    <div className="flex mt-2">
                      <span className="text-black font-semibold">Fare Type:</span><span className="text-gray-600 ml-2">{item.type}</span>
                    </div>
                    <p className="text-gray-600 mt-1 text-sm">{flightSummaryInfo?.flightSummary || 'No flight summary available'}</p>
                  </div>
                </div>
              );
            })
          ) : (
            Array(3).fill().map((_, index) => (
              <div key={index} className="bg-white rounded-lg flex-shrink-0 w-72 animate-pulse">
                <div className="w-full h-48 bg-gray-300 rounded-lg mb-4"></div>
                <div className="w-2/3 h-4 bg-gray-300 rounded mb-2"></div>
                <div className="w-1/3 h-4 bg-gray-300 rounded mb-2"></div>
                <div className="w-full h-4 bg-gray-300 rounded mb-2"></div>
                <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
              </div>
            ))
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Flight Details"
      >
        {selectedItem && (
          <>
    <FlightDetails tripData={selectedItem} />
          </>
        )}
      </Modal>
    </section>
  );
};

export default Flights;