import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer class="bg-white text-black mt-auto">
            <div class="container mx-auto py-8 px-4">
                <div class="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
                <Link to="/privacy" 
                    class="hover:text-gray-700"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    >Privacy</Link>

                    <Link to="/terms" 
                    class="hover:text-gray-700"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    >Terms of Service</Link>

                    <Link to="https://twitter.com/kingjulian_i"
                        class="hover:text-gray-700"
                        target={"_blank"}
                        rel="noopener noreferrer">
                        <i class="fab fa-twitter"></i> Twitter
                    </Link>
                    <p class="text-gray-600">&copy; 2024 Vaaycay. All rights reserved.</p>
                </div>

            </div>
        </footer>
    )
}

export default Footer