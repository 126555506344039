import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import { Loader2 } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import io from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../helper/SessionContext';
import ProfileHeader from '../components/ProfileHeader';
import Flights from '../sections/Flights';
import AirBnb from '../sections/AirBnb';
import Hotels from '../sections/Hotels';
import Visa from '../sections/Visa';
import { safeExtractData } from '../helper/helper';
import Footer from '../components/Footer';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD');
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT;
const socket_url = is_prod ? 'https://api.vaaycay.com' : 'https://staging.vaaycay.com' //Temporary

const Profile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const requestComplete = useRef(false);
    const [requestSuccessful, setRequestSuccessful] = useState(false);
    const [query, setQuery] = useState('');
    const { sessionId, clearSessionId } = useContext(SessionContext);
    const [flight_data, setFlightData] = useState([]);
    const [flight_message, setFlightMessage] = useState('');
    const [airbnb_data, setAirbnbData] = useState([]);
    const [airbnb_message, setAirbnbMessage] = useState('');
    const [hotel_data, setHotelData] = useState([]);
    const [hotel_message, setHotelMessage] = useState('');
    const [visa_data, setVisaData] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [userdata, setUserdata] = useState(null)
    const [ip, setIP] = useState("");
    const navigate = useNavigate();
    const [tripId, setTripId] = useState(null);
    const socketRef = useRef(null);
    const currentRoomRef = useRef(null);
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        socketRef.current = io(socket_url);

        socketRef.current.on('connect', () => {
            console.log('Connected to server');
        });

        // Clean up on component unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (tripId) {
            if (currentRoomRef.current) {
                socketRef.current.emit('leave', currentRoomRef.current);
            }

            socketRef.current.emit('join', tripId);
            currentRoomRef.current = tripId;

            socketRef.current.on('data_update', (data) => {
                switch (data.update_type) {
                    case 'flight':
                        setFlightData(data.data);
                        setFlightMessage(data.message)
                        break;
                    case 'airbnb':
                        setAirbnbData(data.data);
                        setAirbnbMessage(data.message)
                        break;
                    case 'hotel':
                        setHotelData(data.data);
                        setHotelMessage(data.message)
                        break;
                    default:
                        break;
                }
            });
        }

        // Clean up event listener on tripId change
        return () => {
            socketRef.current.off('data_update');
        };
    }, [tripId]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        clearData()

        setTimeout(() => {
            setIsLoading(false);
            if (!requestComplete.current) {
                toast("😢 The request has timed out. You will not be charged.");
            }
        }, 20000);

        if (query !== '') {
            let data = JSON.stringify({
                "query": query,
                "ip_address": ip
              });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${base_url}/search`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionId
                },
                data: data
            };

            try {
                const response = await axios.request(config);
                setIsLoading(false);
                requestComplete.current = true;

                if (response.status === 200) {
                    setRequestSuccessful(true);
                    const data = response.data;
                    setMetadata(data.metadata);
                    setVisaData(data.data.visa_requirements);
                    setTripId(data.request_id);
                } else {
                    toast(`😢 ${response.data.message || 'Your request was unsuccessful, Please try again.'}`);
                }
            } catch (error) {
                setIsLoading(false);
                requestComplete.current = true;
                if (error.response.status === 401) {
                    // clearSessionId()
                    // navigate(`/auth`);
                } else {
                    toast(`😢 ${error.response.data.message || 'Your request was unsuccessful, Please try again.'}`);
                }
            }
        }
    };

    const handleShareClick = () => {
        const url = `${process.env.REACT_APP_URL}/details?trip_id=${tripId}`
        navigator.clipboard.writeText(url).then(() => {
            toast("🥳 URL copied to clipboard!");
        }).catch(err => {
            toast("🥳 Hmmm something isn't quite right");
        });
    };

    const clearData = () => {
        setFlightData([])
        setAirbnbData([])
        setHotelData([])
        setVisaData(null)
        setMetadata(null)
        requestComplete.current = false;
        setRequestSuccessful(false)
    }

    const fetchUser = useCallback(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/me`,
            headers: {
                'Authorization': sessionId
            }
        };
    
        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data;
                    setUserdata(data.user_data);
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response?.status === 401) {
                    clearSessionId();
                    navigate(`/auth`);
                }
            });
    }, [sessionId, navigate, clearSessionId]);

    useEffect(() => {
        
    }, [])

    const getIp = async () => {
        try {
            const res = await axios.get("https://corsproxy.io/?https://api.ipify.org/?format=json");
            setIP(res.data?.ip || '');
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (sessionId && !hasFetched) {
        fetchUser()
        getIp()
        setHasFetched(true);
        }
    },[sessionId, hasFetched, fetchUser])

    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Vaaycay - Profile</title>
                <meta name="description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />
                <meta name="keywords" content="AI travel assistant, travel deals, cheap flights, hotel discounts, visa information, vacation planning, best travel prices" />
                <meta name="author" content="Vaaycay" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />


                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vaaycay.com" />
                <meta property="og:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="og:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://vaaycay.com" />
                <meta property="twitter:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="twitter:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />

            </Helmet>

            <ProfileHeader content={userdata} />
            <main className="container mx-auto mt-2 p-8">
                <div className="text-center">
                    <h1 className="text-4xl font-bold">Your perfect vacation starts here.</h1>
                    <div className="mt-2 flex justify-center space-x-4"></div>
                    <form className="mt-4 flex justify-center" onSubmit={handleSubmit}>
                        <div className="flex items-center bg-gray-100 rounded-full p-2 mb-4 mt-4 md:w-2/3">
                            <i className="fas fa-search w-4 h-4 mr-2 px-4"></i>
                            <input
                                type="text"
                                placeholder="A trip to Cancun for 2 people..."
                                className="bg-transparent flex-grow outline-none"
                                value={query}
                                required
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded-full ml-2"
                                disabled={isLoading}
                            >
                                {isLoading ?
                                    <Loader2 className="w-6 h-6 animate-spin" />
                                    :
                                    'Search'
                                }
                            </button>
                        </div>
                    </form>
                    <p className="text-gray-500 text-sm px-10">
                        Include your city, destination, number of people, dates, info about your passport, and other details to find the perfect trip.
                    </p>
                </div>
                {requestSuccessful && (
                    <div>
                        <section className="mt-8 mb-12 flex flex-col md:flex-row justify-between items-center md:items-start">
                            <div className="md:w-1/2 text-left">
                                <h1 className="text-4xl font-bold">{safeExtractData(metadata?.country)}</h1>
                                <p className="mt-2 text-gray-600">{safeExtractData(metadata?.description)}</p>
                            </div>
                            <button
                                className="px-4 py-3 text-white bg-[#1E293B] rounded-lg flex items-center mt-4 md:mt-0"
                                onClick={handleShareClick}
                            >
                                <i className="fa-solid fa-arrow-up-from-bracket mr-2"></i>
                                Share
                            </button>
                        </section>

                        <Visa content={visa_data} />

                        <Flights content={flight_data} description={flight_message}/>

                        <AirBnb content={airbnb_data} description={airbnb_message}/>

                        <Hotels content={hotel_data} description={hotel_message}/>
                    </div>
                )}

            </main>
            <ToastContainer />
            <Footer />
        </div>
    );
};

export default Profile;
