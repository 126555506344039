import React from 'react';

const FlightInfo = ({ tripData }) => {
    const formatTime = (time) => new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formatDate = (time) => new Date(time).toLocaleDateString();

    const getCarriers = (legs) => {
        let carriers = [];
        for (let i = 0; i < legs.length; i++) {
            if (i === 0 || legs[i].carriers[0].carrier_name !== legs[i - 1].carriers[0].carrier_name) {
                carriers.push(legs[i].carriers[0].carrier_name);
            }
        }
        return carriers.join(', ');
    };

    const getStopsText = (legs) => {
        const stops = legs.length - 1;
        return stops === 1 ? '1 stop' : `${stops} stops`;
    };

    return (
        <div className="mt-4 mb-8">
            {tripData.trip_breakdown.map((trip, index) => (
                <div key={index} className="flex items-start justify-between mt-4 text-xs">
                    <img 
                        src={trip.legs[0].carriers[0].carrier_logo} 
                        alt="Airline Logo" 
                        className="h-6 w-6 mr-2" 
                    />
                    <div className="w-1/3">
                        <div className="flex flex-col">
                            <h1 className="text-sm font-bold">{formatTime(trip.departure_time)}</h1>
                            <p className="text-gray-600">{trip.legs[0].depature_airport_code}</p>
                            <p className="text-gray-600">{formatDate(trip.departure_time)}</p>
                        </div>
                        <p className="text-gray-600">{getCarriers(trip.legs)}</p>
                    </div>
                    <div className="text-center mx-4 w-1/4">
                        <p className="text-gray-600">{Math.floor((new Date(trip.arrival_time) - new Date(trip.departure_time)) / 3600000)}h {(Math.floor((new Date(trip.arrival_time) - new Date(trip.departure_time)) / 60000)) % 60}m</p>
                        <div className="border-t border-gray-300 mt-1 mb-1">
                            <div className="flex justify-between">
                                <div className="w-full text-center">
                                    <span className="text-gray-600">{getStopsText(trip.legs)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col">
                            <h1 className="text-sm font-bold">{formatTime(trip.arrival_time)}</h1>
                            <p className="text-gray-600">{trip.legs[trip.legs.length - 1].arrival_airport_code}</p>
                            <p className="text-gray-600">{formatDate(trip.arrival_time)}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FlightInfo;
