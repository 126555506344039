import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD')
const socket_url = is_prod ? 'https://api.vaaycay.com' : 'https://staging.vaaycay.com' 

const CollaborativeCursor = ({ roomId }) => {
    const [cursors, setCursors] = useState({});
    const socket = useRef(null);

    useEffect(() => {
        socket.current = io(socket_url);

        socket.current.on('connect', () => {
            console.log('Connected to server:', socket.current.id);

            // Join the specified room
            socket.current.emit('join', roomId);

            // Listen for mouse move events from the server
            socket.current.on('mouse_move', (data) => {
                setCursors((prevCursors) => ({ ...prevCursors, [data.id]: { x: data.x, y: data.y, label: data.label } }));
            });

            // Clean up on disconnect
            socket.current.on('disconnect', () => {
                console.log('Disconnected from server:', socket.current.id);
            });
        });

        return () => {
            socket.current.disconnect();
        };
    }, [roomId]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const { clientX: x, clientY: y } = event;
            const scrollX = window.scrollX;
            const scrollY = window.scrollY;
            socket.current.emit('mouse_move', { roomId, x: x + scrollX, y: y + scrollY, id: socket.current.id, label: 'Viewer' });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [roomId]);

    return (
        <div>
            {Object.keys(cursors).map((key) => (
                <div
                    key={key}
                    style={{
                        position: 'absolute',
                        left: `${cursors[key].x - window.scrollX}px`,
                        top: `${cursors[key].y - window.scrollY}px`,
                        pointerEvents: 'none',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div style={{
                        position: 'relative',
                        backgroundColor: '#5A31F4',
                        color: 'white',
                        padding: '4px 8px',
                        borderRadius: '8px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap'
                    }}>
                        {cursors[key].label}
                        <div style={{
                            position: 'absolute',
                            width: '0',
                            height: '0',
                            borderLeft: '6px solid transparent',
                            borderRight: '6px solid transparent',
                            borderTop: '6px solid #5A31F4',
                            bottom: '-6px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CollaborativeCursor;
