import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD')
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT

const Authentication = () => {
    const [email, setEmail] = useState('');
 
    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = async () => {
        setIsLoading(true)
        if (email) {
            let data = JSON.stringify({
                "email_address": email
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${base_url}/auth`,
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : data
              };
              
              axios.request(config)
              .then((response) => {
                setIsLoading(false)
                setEmail('')
                const data = response.data
                if (response.status === 200) {
                    console.log('auth success')
                    toast(`🥳 ${data.message}`)
                   // navigate(`/details?trip_id=${data.request_id}`);
                } else {
                    toast(`😢 ${data.message || 'Your request was unsuccessful, Please try again.'}`)
                }
              })
              .catch((error) => {
                setEmail('')
                setIsLoading(false)
                console.log(error);
              });
        }
    };
    return (
        <div className="bg-black text-white flex items-center justify-center h-screen">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Vaaycay - Sign in</title>
                <meta name="description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />
                <meta name="keywords" content="AI travel assistant, travel deals, cheap flights, hotel discounts, visa information, vacation planning, best travel prices" />
                <meta name="author" content="Vaaycay" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />


                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vaaycay.com" />
                <meta property="og:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="og:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://vaaycay.com" />
                <meta property="twitter:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="twitter:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />

            </Helmet>
            <div className="flex w-full max-w-md">
                <div className="w-full flex flex-col items-center justify-center p-8">
                    <div className="w-full max-w-md">
                        <h2 className="text-2xl font-bold mb-4">Continue to your account</h2>
                        <p className="mb-6">Enter your email below</p>
                        <input
                            type="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 mb-4 border border-gray-600 rounded bg-black text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button 
                        onClick={handleLogin} 
                        className="w-full px-4 py-2 mb-4 bg-white text-black font-semibold rounded"
                        disable={isLoading}
                        >
                              {isLoading ?
                                    <Loader2 className="w-6 h-6 animate-spin" />
                                    :
                                    'Continue with Email'
                                }
                            
                        </button>
                        <p className="text-gray-400 text-sm mb-4">If you do not have an account, we will automatically create one for you</p>
                        <p className="text-gray-400 text-sm">
                            By clicking continue, you agree to our{' '}
                            <Link to="/terms" 
                            className="underline"
                            target={"_blank"}
                            rel="noopener noreferrer"
                            >
                                Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link to="/privacy" 
                            className="underline"
                            target={"_blank"}
                            rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </Link>.
                        </p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Authentication;
