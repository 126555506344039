// ProtectedRoutes.js
import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { SessionContext } from './SessionContext';

const ProtectedRoute = () => {
    const { sessionId } = useContext(SessionContext);

    console.log('Session ID in ProtectedRoute:', sessionId); // For debugging

    return sessionId ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoute;