import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../index.css';
import io from 'socket.io-client';
import Flights from '../sections/Flights';
import AirBnb from '../sections/AirBnb';
import Hotels from '../sections/Hotels';
import Visa from '../sections/Visa';
import Footer from '../components/Footer';
import { safeExtractData } from '../helper/helper';
import CollaborativeCursor from '../components/CollaborativeCursor';

const is_prod = (process.env.REACT_APP_ENVIRONMENT === 'PROD')
const base_url = is_prod ? process.env.REACT_APP_PROD_ENDPOINT : process.env.REACT_APP_STAGING_ENDPOINT
const socket_url = is_prod ? 'https://api.vaaycay.com' : 'https://staging.vaaycay.com' //Temporary

const Details = () => {
    const [flight_data, setFlightData] = useState([]);
    const [flight_message, setFlightMessage] = useState('');
    const [airbnb_data, setAirbnbData] = useState([]);
    const [airbnb_message, setAirbnbMessage] = useState('');
    const [hotel_data, setHotelData] = useState([]);
    const [hotel_message, setHotelMessage] = useState('');
    const [visa_data, setVisaData] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const trip_id = queryParams.get('trip_id');

    useEffect(() => {

        const socket = io(socket_url);

        socket.on('connect', () => {
            console.log('Connected to server:');

            // Join a room
            socket.emit('join', trip_id);

            // Listen for messages from the server
            socket.on('data_update', (data) => {
                switch (data.update_type) {
                    case 'flight':
                        setFlightData(data.data);
                        setFlightMessage(data.message)
                        break;
                    case 'airbnb':
                        setAirbnbData(data.data)
                        setAirbnbMessage(data.message)
                        break
                    case 'hotel':
                        setHotelData(data.data)
                        setHotelMessage(data.message)
                        break
                    default:
                        break;
                }
            });
        });

        // Clean up on component unmount
        return () => {
            socket.disconnect();
        };
    }, [trip_id]);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/details?trip_id=${trip_id}`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    setMetadata(data.metadata)
                    setVisaData(data.data.visa_requirements)
                    if (data.data.flights_data && data.data.flights_data.length  > 0) {
                        setFlightData(data.data.flights_data)
                        setFlightMessage('Here are the flights we found for you! Select the one that best fits your schedule and budget for a smooth trip.')
                    }
                    if (data.data.airbnb_data && data.data.airbnb_data.length > 0) {
                        setAirbnbData(data.data.airbnb_data)
                        setAirbnbMessage('Check out these amazing Airbnb stays we found for you! Enjoy a unique and personalized experience at your destination.')
                    }
                    if (data.data.hotel_data && data.data.hotel_data.length > 0) {
                        setHotelData(data.data.hotel_data)
                        setHotelMessage('Here are the best hotels we found for your stay! Enjoy a comfortable and convenient trip.')
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                toast(`😢 ${error.response?.data?.message || 'Your request was unsuccessful, Please try again.'}`)
            });

    },[trip_id])

    const handleShareClick = () => {
        const url = window.location.href; // Get the current URL
        navigator.clipboard.writeText(url).then(() => {
            toast("🥳 URL copied to clipboard!")
        }).catch(err => {
            toast("🥳 Hmmm something isn't quite right")
        });
    };
    return (
        <div className="bg-white">
            <Helmet>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Vaaycay - Trip Details</title>
                <meta name="description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />
                <meta name="keywords" content="AI travel assistant, travel deals, cheap flights, hotel discounts, visa information, vacation planning, best travel prices" />
                <meta name="author" content="Vaaycay" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" />


                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vaaycay.com" />
                <meta property="og:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="og:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://vaaycay.com" />
                <meta property="twitter:title" content="Vaaycay - Your AI Travel Assistant" />
                <meta property="twitter:description" content="Discover the best travel deals with Vaaycay. Our AI searches the web for the best prices on flights and accommodations, and provides essential visa information. Start planning your perfect vacation today!" />

            </Helmet>

            <Header />
            <div class="w-full h-64 bg-cover bg-center"
                style={{ backgroundImage: `url(${metadata?.display_image === '' ? 'https://res.cloudinary.com/verifiedlyllc/image/upload/v1719194595/beqqprhanholxxxql6tr.jpg' : metadata?.display_image })` }}>
            </div>

            <main class="container mx-auto mt-2 p-8">
                <section class="mt-8 mb-12 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
                    <div class="md:w-1/2">
                        <h1 class="text-4xl font-bold">{safeExtractData(metadata?.country)}</h1>
                        <p class="mt-2 text-gray-600">{safeExtractData(metadata?.description)}</p>
                    </div>
                    <button 
                    class="px-4 py-3 text-white bg-[#1E293B] rounded-lg flex items-center"
                    onClick={handleShareClick}
                    >
                        <i class="fa-solid fa-arrow-up-from-bracket mr-2"></i>
                        Share
                    </button>
                </section>
                <Visa content={visa_data} />

                <Flights content={flight_data} description={flight_message} />

                <AirBnb content={airbnb_data} description={airbnb_message}/>

                <Hotels content={hotel_data} description={hotel_message}/>
            </main>
            <ToastContainer />
            <Footer />
            <CollaborativeCursor roomId={trip_id} />
        </div>
    );
}

export default Details;