// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Details from './pages/Details';
import Authentication from './pages/Authentication';
import Confirm from './pages/Confirm';
import Profile from './pages/Profile';
import History from './pages/History';
import Privacy from './pages/Privacy';
import TermsOfService from './pages/TermsOfService';
import { SessionProvider } from './helper/SessionContext';
import ProtectedRoute from './helper/ProtectedRoutes';

function App() {
  return (
    <Router>
      <SessionProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details" element={<Details />} />
          <Route path="/auth" element={<Authentication />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route element={<ProtectedRoute />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/history" element={<History />} />
          </Route>
        </Routes>
      </SessionProvider>
    </Router>
  );
}

export default App;
