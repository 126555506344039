
export const safeExtractData = (value) => {
    try {
        return value
    } catch {
        return ''
    }
}

export const formatDateString = (dateString)  => {
    const date = new Date(dateString);
    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
}