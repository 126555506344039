import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from './Modal';
import Pricing from './Pricing';
import { SessionContext } from '../helper/SessionContext';

const ProfileHeader = ({ content }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { clearSessionId } = useContext(SessionContext);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSignOut = () => {
        // Handle sign out logic here
        console.log('Sign out');
        clearSessionId()
        navigate(`/`);

    };

    const openModal = () => {
        setIsDropdownOpen(false)
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <header className="bg-white shadow">
            <div className="container mx-auto p-4 flex items-center justify-between">
                <div className="flex items-center">
                    <Link to="/profile">
                        <i className="fa-solid fa-plane-departure cursor-pointer"></i>
                    </Link>
                </div>
                <nav className="flex items-center space-x-4 relative">
                    <div className="flex items-center space-x-2">
                        <span className="text-gray-700">Searches left: {content?.search_credits}</span>
                        <div className="relative inline-block text-left">
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    id="menu-button"
                                    aria-expanded={isDropdownOpen}
                                    aria-haspopup="true"
                                    onClick={toggleDropdown}
                                >
                                    <img
                                        src="https://via.placeholder.com/24"
                                        alt="User"
                                        className="rounded-full"
                                    />
                                    <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            {isDropdownOpen && (
                                <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                    <div className="px-4 py-3" role="none">
                                        <p className="text-sm" role="none">Signed in as</p>
                                        <p className="truncate text-sm font-medium text-gray-900" role="none">{content?.email_address}</p>
                                    </div>
                                    <div className="py-1" role="none">
                                        <Link to="/history" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-0">History</Link>
                                        <a href="mailto:sam@blvckapps.com" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-1">Support</a>
                                        <button 
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" 
                                            role="menuitem" 
                                            tabIndex="-1" 
                                            id="menu-item-2"
                                            onClick={openModal}
                                        >
                                            Billing
                                        </button>
                                    </div>
                                    <div className="py-1" role="none">
                                        <form method="POST" action="#" role="none">
                                            <button type="submit" className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-3" onClick={handleSignOut}>Sign out</button>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
                <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel=""
            >
                <Pricing showHeaderAndDescription={false} />
            </Modal>
            </div>
        </header>
    );
};

export default ProfileHeader;
