import React from 'react';
import { Link } from 'react-router-dom';

const RecentSearches = ({ content }) => {
  return (
    <section className="mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8 mt-8 text-center">Recent Public Searches ✈️</h1>
      <div className="overflow-x-auto px-6">
        <div className="flex justify-start space-x-4 mb-8">
          {content.length > 0 ? (
            content.map((item, index) => (
              <Link to={`/details?trip_id=${item.request_id}`} key={index}>
                <div className="flex-shrink-0 w-72 overflow-hidden">
                  <div className="overflow-hidden">
                    <img
                      src={item.metadata.display_image === '' ? 'https://res.cloudinary.com/verifiedlyllc/image/upload/v1719194595/beqqprhanholxxxql6tr.jpg' : item.metadata.display_image}
                      alt={item.alt || 'Vacation image'}
                      className="w-full h-64 object-cover rounded-lg"
                    />
                    <div className="py-4">
                      <h3 className="text-lg font-semibold">{item.title}</h3>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            Array(10).fill().map((_, index) => (
              <div key={index} className="flex-shrink-0 w-72">
                <div className="overflow-hidden bg-white rounded-lg animate-pulse">
                  <div className="w-full h-64 bg-gray-300 rounded-lg mb-4"></div>
                  <div className="p-4">
                    <div className="w-2/3 h-4 bg-gray-300 rounded mb-2"></div>
                    <div className="w-1/3 h-4 bg-gray-300 rounded mb-2"></div>
                    <div className="w-full h-4 bg-gray-300 rounded mb-2"></div>
                    <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default RecentSearches;
